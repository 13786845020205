<template>
  <div>
    <a-drawer
      :title="drawer.title"
      placement="bottom"
      :closable="true"
      :visible="drawer.visible"
      @close="onCloseDrawer"
      height="100%"
      width="100%"
    >
      <post-stats :postId="drawer.postId"></post-stats>
    </a-drawer>
    <div>
      <b-row class="justify-content-start">
        <b-col lg="2" class="text-right mb-3">
          <a-form :form="form"  @submit.prevent="search" layout="inline">
            <a-input-search v-model="form.q" placeholder="Search News" @search="search"></a-input-search>
          </a-form>
        </b-col>
        <b-col lg="3" class="text-right mb-3">
          <a-form-model-item
            class="mb-0"
            label="Portal"
            :label-col="{ span: 4 }"
            >
            <a-select
              :default-value="0"
              style="width: 250px"
              @change="selectPortal">
              <a-select-option :value="0"> All Portal </a-select-option>
              <a-select-option v-for="portal in portalData" :key="portal.id" :value="portal.id"> {{portal.title}} </a-select-option>
            </a-select>
          </a-form-model-item>
        </b-col>
        <b-col lg="3" class="text-right mb-3">
          <a-form-model-item
            class="mb-0"
            label="Sort"
            :label-col="{ span: 4 }"
            >
            <a-select
              :default-value="'published_at'"
              style="width: 250px"
              @change="sortChange">
              <a-select-option v-for="(item,i) in selectSort" :key="i" :value="item.id"> {{item.name}} </a-select-option>
            </a-select>
          </a-form-model-item>
        </b-col>
      </b-row>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <div v-for="(post,index) in postData" :key="index" class="d-flex flex-nowrap align-items-start mb-4 pb-2 border-light border-bottom">
          <!--
          <div class="mr-4 flex-shrink-0 align-self-start kit__utils__avatar kit__utils__avatar--size64">
            <img :src="post.heroImage && post.heroImage.url ? `${post.heroImage.url}?tr=w-175,ar-3-4` : '/resources/images/avatars/avatar-2.png'" :alt="post.createdBy.full_name" />
          </div>
          -->
          <div class="mr-4 flex-shrink-0 ">
            <img class="w-80 rounded" :src="post.heroImage && post.heroImage.url ? `${post.heroImage.url}?tr=w-50,ar-4-3` : '/resources/images/avatars/avatar-2.png'" :alt="post.createdBy.full_name" />
          </div>
          <div class="flex-grow-1">
            <div>
              <div class="d-flex flex-wrap mb-2">
                <div class="mr-auto">
                  <div class="text-gray-6">
                    <span class="text-dark font-weight-bold">
                      <a :href="post.url" target="_blank">
                        <h5 class="title" v-html="post.title"></h5>
                      </a>
                    </span>
                  </div>
                  <div class="text-gray-6">
                    <span class="text-dark font-weight-bold">Portal : {{post.portals[0].title}}</span>
                  </div>
                  <div class="text-gray-6">
                    <span class="text-dark">Penulis : {{
                      (post.authorBy && user.id === post.authorBy.id) ? 'You' :
                        post.createdBy && user.id === post.createdBy.id ? 'You' :
                        post.authorBy && post.authorBy.full_name ? post.authorBy.full_name : post.createdBy.full_name
                    }}</span>
                  </div>
                  <div class="text-muted small">
                    <vue-moments-ago prefix="" :date="post.published_at" lang="en"></vue-moments-ago>
                  </div>
                </div>
              </div>
              <a href="javascript:;" @click=" () => showDrawer(post.id)">
                <small>
                  <i class="fe fe-bar-chart-2"></i> {{kFormat(post.__meta__.visitors_count)}}
                </small>
              </a>
              <template  v-if="post.__meta__ && post.__meta__.reposts_count && post.__meta__.reposts_count > '0' ">
                <small class="ml-4">
                  <a-tooltip placement="bottom" >
                    <i class="fe fe-repeat"></i> {{kFormat(post.__meta__.reposts_count)}}
                    <template slot="title">repost</template>
                  </a-tooltip>
                </small>
              </template>

              <!-- <div class="mb-3">
                <a :href="post.url" target="_blank" class="card overflow-hidden mb-2">
                  <div class="card-body p-0">
                    <div class="row no-gutters">
                      <div class="col-4 pr-2" v-if="post.heroImage || post.imageMedia.length">
                        <img
                          :src="`${post.heroImage ? post.heroImage.url : post.imageMedia[0].url}?tr=w-178,h-178`"
                          :srcset="`${post.heroImage ? post.heroImage.url : post.imageMedia[0].url}?tr=w-178,h-178 1x, ${post.heroImage ? post.heroImage.url : post.imageMedia[0].url}?tr=w-356,h-356 2x`"
                          class="w-100" :alt="post.heroImage ? post.heroImage.alt : post.imageMedia[0].alt" />
                      </div>
                      <div :class="post.heroImage || post.imageMedia.length ? 'col-8 py-2 pr-3 pl-1' : 'col-12 p-3'">
                        <span v-if="post.portals && post.portals.length" class="small d-block mb-2">{{ post.portals[0].domain }}</span>
                        <h5 class="title">{{ post.title }}</h5>
                        <div class="text-muted excerpt" v-html="post.excerpt"></div>
                      </div>
                    </div>
                  </div>
                </a>
              </div> -->
            </div>
          </div>
          <div class="flex-shrink-0">
            <div class="nav-item dropdown">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <a class="nav-link px-0 pt-sm-0" href="javascript: void(0);"><strong class="fe fe-more-vertical"></strong></a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a :href="post.url" target="_blank">Continue Reading <span class="fe fe-external-link small"></span></a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" v-clipboard="post.url">Copy Link</a>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item v-if="user.id === post.createdBy.id">
                    <router-link :title="`Edit ${post.title}`" :to="{ path: `posts/edit/${post.id}`}">Edit</router-link>
                  </a-menu-item>
                  <a-menu-item v-if="user.id === post.createdBy.id">
                    <a class="text-danger" :title="`Delete ${post.title}`" @click.prevent="showDeleteConfirm(post)" href="javascript:;">Delete</a>
                  </a-menu-item>
                  <a-menu-item v-if="!(user.id === post.createdBy.id)">
                    <a href="javascript:;" @click="repost(post.id)"><span class="fe fe-repeat"></span> Repost</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="fetch"></infinite-loading>
      </div>
      <div class="col-lg-12 col-xl-6">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
      </div>
    </div>
    <!-- Repost Form -->
    <a-modal
      ok-text="Continue"
      :width="680"
      v-model="showRepostForm"
      @ok="continueRepost">
      <div v-if="showRepostForm && !loading">
        <b-row no-gutters class="pt-4 mb-4">
          <b-col cols="2" v-if="Object.keys(repostForm.image_media).length">
            <img
              :src="`${repostForm.image_media.url }?tr=w-178,h-178`"
              :srcset="`${repostForm.image_media.url}?tr=w-178,h-178 1x, ${repostForm.image_media.url}?tr=w-356,h-356 2x`"
              class="w-100 rounded" :alt="repostForm.image_media.alt" />
          </b-col>
          <b-col :cols="Object.keys(repostForm.image_media).length ? 10 : 12" :class="repostForm.image_media ? 'px-3' : ''">
            <h5 class="title">{{ repostForm.title }}</h5>
            <div class="text-muted excerpt" v-html="repostForm.excerpt"></div>
          </b-col>
        </b-row>
        <a-form-model spellcheck="false" ref="repostForm" :model="repostForm" :rules="repostRules">
          <a-form-model-item label="New Title" name="title" prop="title" :colon="false" class="mb-0">
            <a-input
              size="large"
              required
              placeholder="Insert title here"
              v-model="repostForm.title"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueMomentsAgo from 'vue-moments-ago'
import { Modal } from 'ant-design-vue'
import { kFormatter } from '../../lib'
import PostStats from '../post/stat.vue'
export default {
  data() {
    return {
      drawer: {
        postId: null,
        visible: false,
        title: '',
      },
      selectSort: [
        {
          id: 'published_at',
          name: 'Recent',
          default: true,
        },
        {
          id: 'visitors_count',
          name: 'Most viewed',
          default: false,
        },
      ],
      selectedSort: 'published_at', // index of selectSort
      form: {
        q: '',
        sort_column: 'published_at',
        sort_value: 'desc',
      },
      postData: [],
      portalData: [],
      page: 1,
      showRepostForm: false,
      originalTitle: '',
      portal_id: '',
      repostRules: {
        title: [
          {
            required: true,
            message: 'Please insert a title',
          },
          {
            validator: this.titleValidator,
            message: 'Please insert new title first',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['settings', 'user']),
    ...mapState('post', {
      posts: state => state.data,
      repostForm: state => state.form,
    }),
    loading() {
      return this.$store.state.post.loading
    },
  },
  created() {
    this.$nprogress.start()
    // this.$store.commit('CHANGE_SETTING', { setting: 'activePortal', value: this.user.portals[0].id })
    const vm = this
    vm.$store.dispatch('portal/FETCH', {
      sort_column: 'domain',
      sort_value: 'asc',
      perPage: 1000,
    }).then((res) => {
      this.$nprogress.done()
      vm.portalData = res.data.data
    }).catch(() => {
      this.$nprogress.done()
    })
  },
  methods: {
    onCloseDrawer() {
      this.drawer.visible = false
      this.$store.commit('post/CLEAR_FORM')
      this.$store.commit('post/CLEAR_VIEWSTATS')
    },
    showDrawer(postId) {
      const vm = this
      vm.drawer.postId = postId
      vm.drawer.visible = true
      const post = vm.postData.find(f => `${f.id}` === `${postId}`)
      vm.drawer.title = post.portals.map(m => m.title).join(', ')
    },
    search($state) {
      this.$nprogress.start()
      const vm = this
      vm.page = 1
      vm.$store.dispatch('post/PUBLIC_FETCH', {
        page: 1,
        perPage: 10,
        q: vm.form.q,
        portal_id: vm.portal_id,
        sort_column: vm.form.sort_column,
        sort_value: vm.form.sort_value,
      }).then((res) => {
        this.$nprogress.done()
        vm.postData = vm.posts
        vm.page++
        if ($state) {
          console.log('Search Post')
          if (res.data.page === res.data.lastPage) {
            $state.complete()
          } else {
            $state.loaded()
          }
        }
      }).catch(() => {
        this.$nprogress.done()
      })
    },
    sortChange(e) {
      const vm = this
      vm.form.sort_column = e
      vm.form.sort_value = 'desc'
      vm.search()
    },
    kFormat(num) {
      return kFormatter(num)
    },
    selectPortal(value, $state) {
      this.$nprogress.start()
      const vm = this
      this.portal_id = value
      const condition = {
        page: 1,
        perPage: 10,
        q: vm.form.q,
        sort_column: vm.form.sort_column,
        sort_value: vm.form.sort_value,
      }

      if (value !== 0) {
        condition.portal_id = value
      }

      vm.$store.dispatch('post/PUBLIC_FETCH', condition).then((res) => {
        vm.postData = vm.posts
        vm.page++
        this.$nprogress.done()
        // if ($state) {
        //   if (res.data.page === res.data.lastPage) {
        //     $state.complete()
        //   } else {
        //     $state.loaded()
        //   }
        // }
      }).catch(() => {
        this.$nprogress.done()
      })
    },
    fetch($state) {
      if (!this.$store.state.post.loading) {
        const vm = this
        vm.$store.dispatch('post/PUBLIC_FETCH', {
          page: vm.page,
          perPage: 10,
          q: vm.form.q,
          portal_id: this.portal_id,
          sort_column: vm.form.sort_column,
          sort_value: vm.form.sort_value,
        }).then((res) => {
          const output = vm.postData.concat(vm.posts)
          vm.postData = output
          vm.page++

          if ($state) {
            if (res.data.page === res.data.lastPage) {
              $state.complete()
            } else {
              $state.loaded()
            }
          }
        })
      }
    },
    repost(id) {
      const vm = this
      vm.showRepostForm = true
      vm.$store.dispatch('post/REPOST', id).then((post) => {
        vm.$set(vm, 'originalTitle', post.data.title)
      })
    },
    continueRepost() {
      const vm = this
      vm.$refs.repostForm.validate(valid => {
        if (valid) {
          vm.$router.push({ path: '/posts/create' })
        }
      })
    },
    titleValidator(rule, value, callback) {
      return value !== this.originalTitle
    },
    showDeleteConfirm(item) {
      const vm = this

      Modal.confirm({
        title: 'Are you sure delete this post?',
        content: 'This post will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          vm.$store.dispatch('post/DELETE', item.id).then((res) => {
            vm.$notification.success({
              message: 'Post Deleted',
              description: 'Successfully deleted.',
            })
            const newPostData = vm.postData.filter((post) => {
              return post.id !== item.id
            })
            vm.$set(vm, 'postData', newPostData)
          })
        },
        onCancel() {},
      })
    },
  },
  components: {
    VueMomentsAgo,
    'post-stats': PostStats,
  },
  destroyed() {
    this.$store.commit('post/CLEAR_DATA')
  },
}
</script>
<style lang="scss" scoped>
.ant-dropdown-menu {
  max-height: calc(100vh - 100px) !important;
  overflow: auto !important;
}
</style>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style scoped>
.card .excerpt,
.card .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card .excerpt {
  -webkit-line-clamp: 3;
}
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
.nav-link{
  padding: 0;
}
</style>
